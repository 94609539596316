<template>
  <div class="c-app flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="8">
          <CCardGroup>
            <CCard class="p-4">
              <CCardBody v-if="isTokenValid == false">
                <div class="row mb-4">
                  <div class="col-md-12">
                     <img
                        class="mx-auto d-block"
                        name="logo"
                        size="custom-size"
                        :height="66"
                        viewBox="0 0 556 134"
                        src="/logo.png"
                        alt="CWASA Logo"
                      />
                    <h4 class="mt-2" style="text-align: center;color: rgb(19 23 150); font-size: 37px; font-weight: bold;">স্মার্ট ওয়াটার মিটারিং সিস্টেম</h4>
                  </div>
                </div>
                <h1 v-if="isNewUser">Set your password</h1>
                <h1 v-else>Set New Password</h1>
                <!-- <ul v-if="errors">
              <li v-for="error in errors" v-bind:key="error">{{ msg }}</li>
            </ul> -->
                <div v-if="serverErrors.length">
                  <CAlert
                    v-model="showDismissibleAlert"
                    color="danger"
                    closeButton
                    v-for="err in serverErrors"
                    :key="err"
                  >
                    {{ err }}
                  </CAlert>
                </div>
                <validation-observer ref="formValidator">
                  <form
                    autocomplete="off"
                    @submit.prevent="resetPassword"
                    method="post"
                  >
                    <ValidationProvider
                      name="Password"
                      v-slot="{ errors }"
                      vid="password"
                      :rules="{ required: true, min: 6 }"
                    >
                      <div class="form-group">
                        <label for="password">Password</label>
                        <CInput
                          type="password"
                          id="password"
                          placeholder="Password"
                          v-model="password"
                          required
                          :addInputClasses="errors.length ? 'is-invalid' : ''"
                        />
                        <p class="text-danger mt-0 pt-0">{{ errors[0] }}</p>
                      </div>
                    </ValidationProvider>
                    <ValidationProvider
                      name="Password Confirmation"
                      v-slot="{ errors }"
                      vid="password_confirmation"
                      :rules="{ required: true, min: 6 }"
                    >
                      <div class="form-group">
                        <label for="email">Confirm Password</label>
                        <CInput
                          type="password"
                          id="password_confirmation"
                          placeholder="Password Confirmation"
                          v-model="password_confirmation"
                          required
                          :addInputClasses="errors.length ? 'is-invalid' : ''"
                        />
                        <p class="text-danger mt-0 pt-0">{{ errors[0] }}</p>
                      </div>
                    </ValidationProvider>
                    <button type="submit" class="btn btn-primary">
                      Update
                    </button>
                  </form>
                </validation-observer>
              </CCardBody>
              <CCardBody v-else>
                <div class="row mb-4">
                  <div class="col-md-12">
                     <img
                        class="mx-auto d-block"
                        name="logo"
                        size="custom-size"
                        :height="66"
                        viewBox="0 0 556 134"
                        src="/logo.png"
                        :alt="appTitle"
                      />
                  </div>
                </div>
                <h1 class="text-danger">Token has been expired!!</h1>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      appTitle: process.env.VUE_APP_NAME,
      token: null,
      password: null,
      password_confirmation: null,
      has_error: false,
      showDismissibleAlert: false,
      serverErrors: [],
      isTokenValid: "",
      isNewUser: "",
    };
  },
  methods: {
    resetPassword() {
      this.axios
        .post("auth/reset-password", {
          token: this.token,
          password: this.password,
          password_confirmation: this.password_confirmation,
          new_user: this.isNewUser,
        })
        .then((res) => {
          console.log(res);
          this.$toastr.s(
            "success",
            "Password successfully reset.",
            "Password reset"
          );
          this.$router.push({ name: "Login" });
        })
        .catch((err) => {
          this.showDismissibleAlert = true;
          this.serverErrors = [];
          if (err.response.data.message) {
            this.serverErrors.push(err.response.data.message);
            this.$refs.formValidator.setErrors(err.response.data.errors);
          }
        });
    },
    isTokenExpired() {
      this.axios
        .get("auth/token-expired", {
          params: {
            token: this.token,
          },
        })
        .then((res) => {
          this.isTokenValid = res.data.valid;
          console.log(this.isTokenValid, "hfg");
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    this.isNewUser = this.$route.query.new;
    this.token = this.$route.query.token;
    this.isTokenExpired();
  },
};
</script>
